export const LOCATION_FILTER = 'location_filter';
export const POST_DETAILS = 'post_details';
export const MESSAGES_BOX = 'messages_box';

export const COMMUNICATION_ENABLE_NOTES = 'COMMUNICATION_ENABLE_NOTES';
export const COMMUNICATION_ENABLE_BASIC_STATISTICS =
  'COMMUNICATION_ENABLE_BASIC_STATISTICS';
export const COMMUNICATION_ENABLE_ASSIGNMENTS =
  'COMMUNICATION_ENABLE_ASSIGNMENTS';
export const COMMUNICATION_ENABLE_LABELS = 'COMMUNICATION_ENABLE_LABELS';
export const COMMUNICATION_ENABLE_ADVANCED_STATISTICS =
  'COMMUNICATION_ENABLE_ADVANCED_STATISTICS';
export const COMMUNICATION_ENABLE_AUTO_REPLY =
  'COMMUNICATION_ENABLE_AUTO_REPLY';
export const COMMUNICATION_ENABLE_DIRECT_MESSAGES =
  'COMMUNICATION_ENABLE_DIRECT_MESSAGES';
export const COMMUNICATION_ENABLE_COMMENTS = 'COMMUNICATION_ENABLE_COMMENTS';
export const COMMUNICATION_ENABLE_REVIEWS = 'COMMUNICATION_ENABLE_REVIEWS';
export const COMMUNICATION_ENABLE_SCHEDULED_ANSWERS =
  'COMMUNICATION_ENABLE_SCHEDULED_ANSWERS';
export const COMMUNICATION_ENABLE_SENTIMENT_ANALYSIS =
  'COMMUNICATION_ENABLE_SENTIMENT_ANALYSIS';

export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';
export const APPROVED = 'APPROVED';

export const BODY = 'body';
export const BUTTON = 'button';
export const HEADER = 'header';

export const IMAGE = 'image';
export const VIDEO = 'video';
export const DOCUMENT = 'document';

export const WHATSAPP_TEMPLATE_STATUS = {
  APPROVED,
  PENDING,
  REJECTED
};
export const ADDITIONAL_DATA_TYPE_WHATSAPP_TEMPLATE = 'whatsapp_template';

export const param_template_types = {
  BODY,
  BUTTON,
  HEADER
};
export const param_template_formats = {
  IMAGE,
  VIDEO,
  DOCUMENT
};

export const PRIORITIES = [
  {
    value: 0,
    name: 'low',
    color: '#17a2b8',
    background: 'rgba(23, 162, 184, 0.125)'
  },
  {
    value: 1,
    name: 'medium',
    color: '#ffc107',
    background: 'rgba(255, 193, 7, 0.125)'
  },
  {
    value: 2,
    name: 'high',
    color: '#dc3545',
    background: 'rgba(220, 53, 69, 0.125)'
  }
];

export const TIME_BEFORE_IDLE = 5 * 60 * 1000;
export const PULLING_INTERVAL_CONVERSATIONS_LIST = 1 * 60 * 1000; // 1 minute * 60 seconds * 1000 milliseconds; unit is milliseconds
export const PULLING_INTERVAL_MESSAGES = 30 * 1000; // 1 minutes * 60 seconds * 1000 milliseconds; unit is milliseconds
// TODO CHANGE TO 1 30
